// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

var jQuery = require("jquery");

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
window.jQuery = $;
window.$ = $;

global.toastr = require("toastr");

import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks";
// import * as ActiveStorage from "@rails/activestorage";

Rails.start();
// Turbolinks.start();
// ActiveStorage.start();

import "jquery";
import "popper.js";
import "bootstrap";

require("jquery.maskedinput/src/jquery.maskedinput");
require("./jquery-ui.min");
require("./masks");
require("./jstree");
